<template>
    <v-container fluid class="pa-0 ma-0">
        <v-card class="d-flex pa-0 ma-0" tile>
            <v-scroll-x-transition appear>
                <v-img src="@/assets/images/certified-doctors-mirror.jpg" class="blue lighten-2" transition="slide-x-transition" position="top right" width="100%" height="450">
                    <div class="container--fluid fill-height bottom-gradient" style="width: 100%">
                        <v-container fluid class="fill-height align-end pb-10">
                            <v-row align="end" justify="start">
                                <v-col cols="12" class="pl-5 pl-md-15">
                                    <v-row align="start" justify="start">
                                        <div class="white--text mx-2">
                                            <span class="text-uppercase font-weight-bold">Queremos que te sientas seguro</span>
                                        </div>
                                    </v-row>
                                    <v-row align="start" justify="start">
                                        <div class="white--text mx-2">
                                            <span class="text-h5 text-md-h4 text-uppercase font-weight-bold">Solo Médicos Certificados</span>
                                        </div>
                                    </v-row>
                                    <v-row>
                                        <div style="height: 25px" />
                                    </v-row>
                                    <v-row align="start" justify="start">
                                        <v-responsive :max-width="$vuetify.breakpoint.xsOnly ? 350 : 500">
                                            <div class="white--text mx-2">
                                                <p class="text-left font-weight-light text-subtitle-1">
                                                    Aceptamos profesionales certificados que pasan por un proceso
                                                    riguroso de evaluación, asegurando que tú tengas acceso solo
                                                    a los mejores especialistas certificados de México.
                                                </p>
                                            </div>
                                        </v-responsive>
                                    </v-row>
                                    <v-row>
                                        <div style="height: 10px" />
                                    </v-row>
                                    <v-row align="start" justify="start" v-show="false">
                                        <div class="white--text mx-0">
                                            <v-btn transparent text color="white" plain to="/under-construction"><span>Ver más</span>
                                                <v-icon small>mdi-arrow-right</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-img>
            </v-scroll-x-transition>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "CertifiedDoctorsComponent",
};
</script>

<style scoped>
.bottom-gradient {
    background-image: linear-gradient(to bottom,
            rgba(114, 217, 235, 0.027) 0%,
            rgb(128, 170, 170) 95%);
}

.v-btn {
    text-transform: none !important;
}
</style>
