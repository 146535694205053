<template>
    <v-container fluid class="fill-height text-left pa-0 ma-0">
        <v-row dense no-gutters>
            <v-col cols="12">
                <v-expand-transition>
                    <div v-if="searchPanel && getResultSearch.length > 0">
                        <v-divider/>
                        <v-card class="ma-0" elevation="0" tile flat>
                            <v-card-title class="white--text orange darken-1">
                                <span>Resultados de búsqueda</span>
                                <v-spacer/>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            v-bind="attrs"
                                            v-on="on"
                                            icon dark
                                            v-on:click="hidePanel">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Cerrar Panel</span>
                                </v-tooltip>
                            </v-card-title>
                            <v-virtual-scroll
                                :items="getResultSearch"
                                :item-height="50"
                                height="250">
                                <template v-slot:default="{ index, item }">
                                    <v-list-item :key="index" link @click="showProfile(item)">
                                        <v-list-item-icon>
                                            <v-icon color="blue">
                                                {{ item.icon }}
                                            </v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-action-text style="width:100px;" class="hidden-sm-and-down">
                                            <v-list-item-title v-html="item.module"></v-list-item-title>
                                        </v-list-item-action-text>

                                        <v-list-item-content>
                                            <v-list-item-title v-html="item.data"></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-btn depressed
                                                   small
                                                   @click="showProfile(item)">
                                                Ver Perfil
                                                <v-icon right
                                                        color="orange darken-4">
                                                    mdi-open-in-new
                                                </v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                        <!--<v-divider v-if="index < resultSearch.length - 1" :key="index"></v-divider>-->
                                    </v-list-item>
                                </template>
                            </v-virtual-scroll>
                        </v-card>
                    </div>
                </v-expand-transition>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapState, mapGetters } from "vuex";

    export default {
        name: "SearchPanelComponent",
        data: () => ({
        }),
        computed: {
            ...mapState(['searchPanel']),
            ...mapGetters(['getResultSearch'])
        },
        methods: {
            hidePanel() {
                this.$store.commit('ClearSearchText');
                this.$store.commit('ClearResultSearch');
                this.$store.commit('HideSearchPanel');
            },
            showProfile(item) {
                this.hidePanel();
                this.$router.push({
                    name: item.route,
                    params: {
                        id: item.profile,
                    }
                });
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>