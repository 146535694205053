<template>
    <v-container fluid class="fill-height text-center justify-center mt-0 mb-15 whitebackcolor">
        <v-row align="center" justify="center">
            <!--<v-col cols="12">-->

                <v-card class="d-flex flex-column outerCard" flat
                        max-width="1300"
                        :width="screenWidth"
                        :height="$vuetify.breakpoint.xs ? 250 : 450">
                    <v-card class="d-flex flex-row pa-1 justify-space-between headerCard" flat>
                        <div class="white--text font-weight-bold text-truncate my-auto">
                            Turismo BC
                        </div>
                        <div class="white--text font-weight-bold px-2 text-truncate my-auto">
                            BHC
                        </div>
                    </v-card>
                    <div class="vid-container">
                        <iframe
                            :src="youtubeVideo"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            frameborder="0"
                            height="100%"
                            width="100%">
                        </iframe>
                    </div>
                </v-card>

            <!--</v-col>-->
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "FeaturesComponent",
        data: () => ({
            youtubeVideo: '',
        }),
        computed: {
            screenWidth() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 720;
                    case 'sm': return 720;
                    case 'md': return 720;
                    case 'lg': return 1024;
                    case 'xl': return 1280;
                    default: return 720;
                }
            },
        },
        methods: {
            loadURL(videoUrl) {
                const youtubeEmbedTemplate = 'https://www.youtube.com/embed/';
                const url = videoUrl.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                console.log("url", url);
                const YId = undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
                console.log("YId", YId);
                if (YId === url[0]) {
                    console.log("not a youtube link");
                } else {
                    console.log("it's  a youtube video");
                }
                const topOfQueue = youtubeEmbedTemplate.concat(YId);
                console.log("topOfQueue", topOfQueue);
                this.youtubeVideo = `${topOfQueue}?autoplay=1&rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&loop=1`;
                console.log("youtubeVideo", this.youtubeVideo);
            },
        },
        mounted() {
            this.loadURL('https://www.youtube.com/watch?v=-OCVa8aw2Bg');
        }
    }
</script>

<style scoped>
    .outerCard {
        background-color: blue !important;
        height: 100vh;
        max-height: 100vh;
    }
    .headerCard {
        background-color: blueviolet !important;
    }
    .vid-container {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background-color: #000;
    }
    .vid-video {
        background-color: #000;
        width: 100%;
        height: 100%;
    }
</style>
