<template>
    <v-container fluid class="pa-0 ma-0">
        <v-card class="d-flex pa-0 ma-0" tile>
            <v-scroll-x-transition appear>
                <v-img class="blue lighten-2" height="450" position="center"
                       src="@/assets/images/number-one-world.jpg" transition="slide-x-transition" width="100%">
                    <div class="container--fluid fill-height bottom-gradient" style="width: 100%">
                        <v-container fluid class="fill-height align-end pb-10">
                            <v-row align="end" justify="start">
                                <v-col cols="12" class="pl-5 pl-md-15">
                                    <v-row align="start" justify="start">
                                        <div class="white--text mx-2">
                                            <span class="text-uppercase font-weight-bold">
                                                Turismo de Salud y Bienestar
                                            </span>
                                        </div>
                                    </v-row>
                                    <v-row align="start" justify="start">
                                        <div class="white--text mx-2">
                                            <span class="text-h5 text-md-h4 text-uppercase font-weight-bold">
                                                Reconocido como el #1 a nivel mundial
                                            </span>
                                        </div>
                                    </v-row>
                                    <v-row>
                                        <div style="height: 25px"/>
                                    </v-row>
                                    <v-row align="start" justify="start">
                                        <v-responsive :max-width="$vuetify.breakpoint.xsOnly ? 350 : 500">
                                            <div class="white--text mx-2">
                                                <p class="text-left font-weight-light text-subtitle-1">
                                                    Con los viajes de turismo médico, los costos totales (incluidos
                                                    los viajes y alojamientos) a menudo son muchos menores que el
                                                    precio de un solo procedimiento en Estados Unidos y demás
                                                    países. Los costos típicos pueden ser de 25% a 80% más bajos.
                                                </p>
                                            </div>
                                        </v-responsive>
                                    </v-row>
                                    <v-row>
                                        <div style="height: 10px"/>
                                    </v-row>
                                    <v-row v-show="false" align="start" justify="start">
                                        <div class="white--text mx-0">
                                            <v-btn color="white" plain text to="/under-construction" transparent>
                                                <span>Ver más</span>
                                                <v-icon small>mdi-arrow-right</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-img>
            </v-scroll-x-transition>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "MedicalTourComponent",
};
</script>

<style scoped>
.bottom-gradient {
    background-image: linear-gradient(to bottom,
    rgba(114, 217, 235, 0.027) 0%,
    rgb(101, 99, 248) 100%);
}

.v-btn {
    text-transform: none !important;
}
</style>
