<template>
    <v-container fluid
                 class="fill-height text-center whitebackcolor justify-center mt-10 mb-0">
        <v-row justify="center" no-gutters>
            <v-col cols="12">
                <span class="blue--text font-weight-regular text-uppercase">
                    ¿Quiénes Somos?
                </span>
            </v-col>
            <v-col cols="12">
                <h1 class="text-h4 font-weight-regular mb-3 headline text-uppercase">
                    Bienvenido a Turismo BC
                </h1>
            </v-col>
            <v-col cols="12">
                <p class="text-body-1 font-weight-light text-lg-center grey--text text--darken-2">
                    Aquí encontrarás los mejores médicos certificados y hospitales
                    a tu disposición.
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "AboutAsComponent",
    };
</script>

<style scoped>
    .whitebackcolor {
        background-color: white;
    }
</style>
