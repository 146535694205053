<template>
    <div>
        <home-page-component/>
    </div>
</template>

<script>
    import HomePageComponent from "@/components/home/HomePageComponent.vue";

    export default {
        name: "HomePage",
        title: "Inicio | Turismo BC",
        components: {
            HomePageComponent,
        }
    };
</script>
