<template>
    <v-container fluid class="fill-height backcolor justify-center py-15 text-center">
        <v-row align="center" justify="center">
            <v-col cols="12">
                <v-row justify="center">
                <span class="blue--text font-weight-regular text-uppercase">
                    Últimas Noticias
                </span>
                </v-row>
                <v-row mb-6 pb-6 justify="center">
                    <h1 class="text-h5 text-md-h4 font-weight-regular mb-3 headline text-uppercase">
                        Manténte informado
                    </h1>
                </v-row>
                <v-row justify="center">
                    <v-responsive :max-width="$vuetify.breakpoint.xsOnly ? 300 : 700">
                        <p class="pt-4 text-body-1 font-weight-light text-lg-center grey--text text--darken-2">
                            Todo lo que necesitas saber sobre lo último en Salud y Bienestar.
                        </p>
                    </v-responsive>
                </v-row>
                <v-row mb-3 justify="center">
                    <div class="white--text mx-0 pt-5">
                        <v-btn transparent text color="primary" plain to="/blogs"><span>Ver todos</span>
                            <v-icon small>mdi-arrow-right</v-icon>
                        </v-btn>
                    </div>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "LastNewsComponent",
};
</script>

<style scoped>
.backcolor {
    background-color: rgba(229, 223, 240, 2);
}

.v-btn {
    text-transform: none !important;
}
</style>
